import logo from './resources/images/Kostronik.png';
import './App.css';


//          Firma Kostronik od ponad 15 lat specjalizuje się w projektowaniu, instalacji oraz serwisie systemów klimatyzacyjnych dla sektora przemysłowego. Naszym celem jest dostarczanie rozwiązań dostosowanych do specyficznych potrzeb każdego klienta, z szczególnym uwzględnieniem przemysłów średniej wielkości.
//  Wierzymy, że nasze doświadczenie, profesjonalizm oraz ciągłe dążenie do doskonalenia technologicznego stanowią klucz do sukcesu zarówno naszej firmy, jak i naszych klientów. Zapraszamy do kontaktu, aby omówić, jak możemy wspomóc rozwój i efektywność Państwa przedsiębiorstwa dzięki nowoczesnym systemom klimatyzacji przemysłowej.
/*
<ul>

            <li>Projektowanie indywidualnych rozwiązań,</li>
            <li>Montaż i uruchomienie systemów klimatyzacyjnych,</li>
            <li>Regularny serwis i konserwacja,</li>
            <li>Modernizacja istniejących instalacji,</li>
            <li>Doradztwo techniczne i energetyczne.</li>
          </ul>
           */

//      Naszą misją jest zapewnienie najwyższej jakości środowiska pracy poprzez efektywne i energooszczędne systemy klimatyzacji. Dążymy do tego, by nasze rozwiązania przyczyniały się nie tylko do komfortu pracowników, ale także do zwiększenia efektywności operacyjnej przedsiębiorstw. Wizją firmy jest być liderem w innowacjach technologicznych w branży HVAC, stawiając na ciągły rozwój i implementację nowoczesnych technologii. <br />
    
function App() {
  return (
    <div className="App">
      <header className="App-header">



        <img src={logo} alt="logo" className='log-holder'></img>

        <div className='phone-holder'>
          tel: 600-114-321
        </div>

        <div className='short-description-holder'>
        W Industrialnych Systemach Wentylacji z Automatyzacją specjalizujemy się w dostarczaniu nowoczesnych rozwiązań wentylacyjnych dostosowanych do wymagań współczesnych środowisk przemysłowych. Nasze systemy są zaprojektowane, aby poprawić jakość powietrza, regulować temperaturę i zapewnić bezpieczne i komfortowe warunki pracy w Twoim zakładzie.
        </div>
        <br />
        <br />

        <div className='short-description-holder'>
          <b>Skuteczne Rozwiązania Wentylacyjne Dla Twoich Potrzeb Przemysłowych</b><br /><br />

          W Industrialnych Systemach Wentylacji z Automatyzacją specjalizujemy się w dostarczaniu nowoczesnych rozwiązań wentylacyjnych dostosowanych do wymagań współczesnych środowisk przemysłowych. Nasze systemy są zaprojektowane, aby poprawić jakość powietrza, regulować temperaturę i zapewnić bezpieczne i komfortowe warunki pracy w Twoim zakładzie.

        </div>


        <div className='short-description-holder'>
          <b>Nasze Produkty i Usługi</b><br /><br />

          Kostronik oferuje pełen zakres usług związanych z klimatyzacją przemysłową, w tym:<br />

          <ul>
            <li><b>Projektowanie i Instalacja Systemów Wentylacyjnych:</b> Od początkowych koncepcji projektowych po instalację i uruchomienie, oferujemy kompleksowe usługi, zapewniając płynną integrację naszych systemów wentylacyjnych w Twoim zakładzie,</li><br />
            <li><b>Utrzymanie i Wsparcie:</b> Nasz zespół ekspertów zapewnia ciągłe usługi utrzymania i wsparcia, aby utrzymać Twoje systemy wentylacyjne w optymalnej kondycji.</li><br />
            <li><b>Modernizacje i Przebudowy:</b> Szukasz możliwości zmodernizowania istniejącego systemu wentylacyjnego lub dostosowania go do nowej technologii? Mamy dla Ciebie rozwiązanie dzięki naszym usługom modernizacji i przebudowy,</li><br />
          </ul>
          
          
        </div>

        <div className='short-description-holder'>
          <b>Dlaczego warto wybrać Kostronik? </b><br /><br />

          Doświadczenie: Ponad 15 lat na rynku pozwoliło nam zdobyć niezbędną wiedzę i doświadczenie, które przekładają się na jakość naszych usług.
          Indywidualne podejście: Każdy projekt traktujemy indywidualnie, dostosowując rozwiązania do specyficznych warunków i potrzeb naszych klientów.
          Nowoczesność: Inwestujemy w najnowsze technologie, aby nasze systemy były nie tylko wydajne, ale także ekologiczne i ekonomiczne.
          Wsparcie techniczne: Zapewniamy kompleksowe wsparcie techniczne oraz szybką reakcję serwisową, dzięki czemu nasze systemy są zawsze sprawne i efektywne.
        </div>
        <div className='short-description-holder'>
          <b>Skontaktuj Się z Nami Dziś</b><br /><br />
          
          Gotowy poprawić jakość powietrza i efektywność swojego zakładu przemysłowego? Skontaktuj się z nami już dziś, aby dowiedzieć się więcej o naszych rozwiązaniach wentylacyjnych i jak możemy pomóc w spełnieniu Twoich konkretnych potrzeb. Pozwól nam być Twoim partnerem w tworzeniu bezpiecznego, zdrowego i produktywnego środowiska pracy.
        
        </div>


        <div className='nip-holder'>
          KOSTRONIK Kamil Osial  <br />
          NIP: 5222777476 <br />
          REGON: 528012744 <br />
        </div>

      </header>
    </div>
  );
}

export default App;
